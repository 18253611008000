<template>
    <admin-dashboard-layout>
        <v-row>
            <v-col class="text-right">
                <v-btn @click="$router.push({name: 'admin.assessments.create'})">Create</v-btn>
            </v-col>
        </v-row>
        <v-data-table
            :loading="loading"
            loading-text="Loading... Please wait"
            :headers="headers"
            :items="assessments"
            :items-per-page="15"
            class="elevation-1"
            @click:row="handleClickRow"
        >
            <template v-slot:item.company="{ item }">
                {{ item.company.name }}
            </template>
            <template v-slot:item.created_at="{ item }">
                {{ item.created_at }} <!--TODO add day.js and default format -->
            </template>
        </v-data-table>
    </admin-dashboard-layout>
</template>

<script>
    import Assessment from "../../../models/Assessment";
    import AdminDashboardLayout from "../../../layouts/AdminDashboardLayout";


    export default {
        name: "users-index",
        components: {AdminDashboardLayout},
        data: function () {
            return {
                assessments: [],
                loading: false,
                headers: [

                    { text: 'Score', value: 'score' },
                    { text: 'Company', value: 'company' },
                    { text: 'Created at', value: 'created_at' },
                ],
            }
        },
        methods: {
            handleClickRow(assessment) {
                this.$router.push({name: 'admin.assessments.show', params: {id: assessment.id}})
            },
            async getConsultations() {
                this.loading = true
                let assessments = new Assessment();
                this.assessments = await assessments.get()
                this.loading = false
            }
        },
        async mounted() {
            await this.getConsultations()
        },
    }
</script>

<style scoped>

</style>
